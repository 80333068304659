import React, { Component } from 'react';
import tv from '../img/tv.png'
import fan from '../img/fan.svg'
import bulb from '../img/bulb.svg'
import laptop from '../img/laptop.svg'
import fridge from '../img/fridge.svg'
import ac from '../img/ac.svg'
import freezer from '../img/freezer.svg'



class Calculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totaltv: 0,
            totalfan: 0, totalbulb: 0, totalfreezer: 0, totallaptop: 0, totalfridge: 0, totalac: 0, device: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        switch (event.target.name) {
            case 'tv':
                this.setState({ totaltv: (event.target.value * 105) }, function () {
                });
                break;
            case 'fan':
                this.setState({ totalfan: (event.target.value * 112) }, function () {
                });
                break;
            case 'bulb':
                this.setState({ totalbulb: (event.target.value * 38) }, function () {
                });
                break;
            case 'freezer':
                this.setState({ totalfreezer: (event.target.value * 750) }, function () {
                });
                break;
            case 'laptop':
                this.setState({ totallaptop: (event.target.value * 68) }, function () {
                });
                break;
            case 'fridge':
                this.setState({ totalfridge: (event.target.value * 750) }, function () {
                });
                break;
            case 'ac':
                this.setState({ totalac: (event.target.value * 5250) }, function () {
                });
                break;
            default:
                this.setState({ [event.target.name]: event.target.value });
        }
    }

    handleCalculator(total) {
        // this.setState({ device: total })
        // alert(total)
        if(total <= 1){ this.setState({ device: 'iPower Inverter 1kva' })}
        if(total <= 2 && total >1){ this.setState({ device: 'iPower Inverter 2kva' })}
        if(total <= 5 && total >2){ this.setState({ device: 'iPower Inverter 5kva' })}
        if(total <= 10 && total >5){ this.setState({ device: 'iPower Inverter 10kva' })}
        if(total <= 20 && total >10){ this.setState({ device: 'iPower Inverter 20kva' })}
        if(total <= 30 && total >20){ this.setState({ device: 'iPower Inverter 30kva' })}
        if(total <= 40 && total >30){ this.setState({ device: 'iPower Inverter 40kva' })}
        if(total <= 50 && total >40){ this.setState({ device: 'iPower Inverter 50kva' })}

    }
    render() {
        var total = (this.state.totaltv + this.state.totalfan + this.state.totalbulb + this.state.totalfreezer + this.state.totalac + this.state.totalfridge + this.state.totallaptop) / 1000;
        return (
            <div >
                <section className="uk-section uk-section-small">
                </section>
              
                <section className="uk-section">
                    <div className="uk-container">
                        <h4 className='uk-text-left'>Ipower Calculator</h4>
                        <hr />
                        <p>How much power do you really need? </p><p>
                            Use our iPower calculator to get a monthly estimate of which data plan best fits your lifestyle based on your usage.</p>

                        <div className="uk-grid-medium uk-child-width-1-5@s uk-text-left uk-margin-medium-top" data-uk-grid>
                            <div>
                                <div className="uk-margin">
                                    <label className="uk-form-label uk-text-bold">Email Address</label>
                                    <div className="uk-form-controls">
                                        <input className="uk-input calc_input" type="text" placeholder="" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="uk-margin">
                                    <label className="uk-form-label uk-text-bold">Phone Number</label>
                                    <div className="uk-form-controls">
                                        <input className="uk-input calc_input" type="text" placeholder="" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="uk-margin">
                                    <label className="uk-form-label uk-text-bold">Location</label>
                                    <div className="uk-form-controls">
                                        <input className="uk-input calc_input" type="text" placeholder="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className='uk-margin-medium-top'>Select appliances and the number of units of each appliance to estimate your power requirements.</p>
                        <div className="uk-grid-large uk-child-width-1-3@s uk-text-left uk-margin-large-top" data-uk-grid>
                            <div>
                                <form className="uk-grid-medium" data-uk-grid>
                                    <div className="uk-width-2-3">
                                        <div>
                                            <img src={tv} width="40" height="40" />
                                            <span className="uk-text-middle calc_text">Television</span>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-3">
                                        <input className="uk-input calc_dark_input" type="number" name='tv' placeholder="0" onChange={this.handleInputChange} />
                                    </div>
                                    <div className="uk-width-2-3">
                                        <div>
                                            <img src={fan} width="40" height="40" />
                                            <span className="uk-text-middle calc_text">Fan</span>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-3">
                                        <input className="uk-input calc_dark_input" type="number" name='fan' placeholder="0" onChange={this.handleInputChange} />
                                    </div>
                                    <div className="uk-width-2-3">
                                        <div>
                                            <img src={bulb} width="40" height="40" />
                                            <span className="uk-text-middle calc_text">Energy Bulb</span>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-3">
                                        <input className="uk-input calc_dark_input" type="number" name='bulb' placeholder="0" onChange={this.handleInputChange} />
                                    </div>
                                    <div className="uk-width-2-3">
                                        <div>
                                            <img src={freezer} width="40" height="40" />
                                            <span className="uk-text-middle calc_text">Deep Freezer </span>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-3">
                                        <input className="uk-input calc_dark_input" type="number" name='freezer' placeholder="0" onChange={this.handleInputChange} />
                                    </div>
                                </form>
                            </div>
                            <div>
                                <form className="uk-grid-medium" data-uk-grid>
                                    <div className="uk-width-2-3">
                                        <div>

                                            <img src={laptop} width="40" height="40" />
                                            <span className="uk-text-middle calc_text">Laptop/ PC</span>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-3">
                                        <input className="uk-input calc_dark_input" type="number" placeholder="0" name='laptop' onChange={this.handleInputChange} />
                                    </div>
                                    <div className="uk-width-2-3">
                                        <div>
                                            <img src={fridge} width="30" height="30" />
                                            <span className="uk-text-middle calc_text">Refrigerator</span>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-3">
                                        <input className="uk-input calc_dark_input" type="number" placeholder="0" name='fridge' onChange={this.handleInputChange} />
                                    </div>
                                    <div className="uk-width-2-3">
                                        <div>
                                            <img src={ac} width="40" height="40" />
                                            <span className="uk-text-middle calc_text">Air Conditioner</span>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-3">
                                        <input className="uk-input calc_dark_input" type="number" placeholder="0" name='ac' onChange={this.handleInputChange} />
                                    </div>
                                </form>
                            </div>
                            {/* <div></div> */}
                        </div>

                        <div className="uk-grid-small uk-margin-top" data-uk-grid>
                            <div className='uk-width-1-1'>
                                <a className="uk-button uk-button-small  uk-margin-medium-top slider_btn" onClick={() => this.handleCalculator(total)}>Calculate</a>
                            </div>
                            <div className='uk-width-1-3'>
                                <p className=" calc_text">Your Power Requirement:</p>
                            </div>
                            <div className='uk-width-2-3'>
                                <h2 className="calc_result_text red">{total}<span className='black calc_text'>KVA</span></h2>
                            </div>
                            <div className='uk-width-1-3'>
                                <p className=" calc_text">Our Reccomendation:</p>
                            </div>
                            <div className='uk-width-2-3@m'>
                                {/* <h2 className="calc_result_text">iPower Inverter 2kva</h2> */}
                                <h2 className="uk-visible@m calc_result_text">{this.state.device}</h2>
                                <h2 className="uk-hidden@m">{this.state.device}</h2>
                                {this.state.device && <a href='https://www.konga.com/ict-connect-limited' target='blank_' className="uk-button uk-button-small  slider_btn uk-margin-left">Buy now</a>}

                            </div>
                        </div>




                        {/* <h2 className='uk-text-center uk-padding'>Choose from our wide array of products.</h2> */}


                    </div>
                </section>

            </div>

        )
    }
}

export default Calculator
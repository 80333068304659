import React from 'react';
import Select from 'react-select';
import axios from 'axios'
import Question from '../img/question.svg'
import logo from '../img/logo_warranty.svg'
import loader from '../../src/img/loader.svg'
import serial from '../../src/img/serial.png'
import activeWarranty from '../../src/img/active_warranty.svg'
import expiredWarranty from '../../src/img/expired_warranty.svg'
import noWarranty from '../../src/img/no_warranty.svg'


class WarrantyResult extends React.Component {
    render() {
        if (this.props.data.deviceActiveContent) {
            var today = new Date();
            var status = ""
            if (new Date(this.props.data.deviceActiveContent.warranty_end_date) >= today) {
                status = <h4 className='uk-text-left uk-text-bold '><img className='uk-margin-right' src={activeWarranty} alt='active warranty  ' />Warranty Status for Device: <span className='green'>Active</span></h4>
            }
            else if (new Date(this.props.data.deviceActiveContent.warranty_end_date) < today) {
                status = <h4 className='uk-text-left uk-text-bold '><img className='uk-margin-right' src={expiredWarranty} alt='expired warranty ' />Warranty Status for Device: <span className='amber'>Expired (Out of Warranty)</span></h4>
            }
        }
        return (
            <div>
                <section className="uk-section">
                    <div className="uk-container">
                        <h4 className='uk-text-left'><span className='red'>Zinox Customer Support</span> - Product Warranty Check</h4>
                        <hr />
                        {status}
                        {this.props.data.deviceNotFound && <h4 className='uk-text-left uk-text-bold red'><img className='uk-margin-right' src={noWarranty} alt='no warranty ' />Product Not Found!</h4>}
                        {this.props.data.deviceActive &&
                            <div data-uk-grid className='uk-grid-small'>
                                <div className='uk-width-1-4@m'>
                                    <h5 className='uk-text-bold'>Device Name</h5>
                                </div>
                                <div className='uk-width-3-4@m'>
                                    <p>{this.props.data.deviceActiveContent.product_id[0].item_detail + ' - ' + this.props.data.deviceActiveContent.product_id[0].item_number}</p>
                                </div>
                                <div className='uk-width-1-4@m'>
                                    <h5 className='uk-text-bold'>Device Model</h5>
                                </div>
                                <div className='uk-width-3-4@m'>
                                    <p>{this.props.data.deviceActiveContent.product_id[0].item_number}</p>
                                </div>
                                <div className='uk-width-1-4@m'>
                                    <h5 className='uk-text-bold'>Serial Number</h5>
                                </div>
                                <div className='uk-width-3-4@m'>
                                    <p>{this.props.data.deviceActiveContent.serial}</p>
                                </div>
                                <div className='uk-width-1-4@m'>
                                    <h5 className='uk-text-bold'>Device Information</h5>
                                </div>
                                <div className='uk-width-3-4@m'>
                                    <p>	{this.props.data.deviceActiveContent.product_id[0].description}</p>
                                </div>
                                <div className='uk-width-1-4@m'>
                                    <h5 className='uk-text-bold'>Warranty type</h5>
                                </div>
                                <div className='uk-width-3-4@m'>
                                    <p>Original Manufacturer Warranty</p>
                                </div>
                                <div className='uk-width-1-4@m'>
                                    <h5 className='uk-text-bold'>Start Date</h5>
                                </div>
                                <div className='uk-width-3-4@m'>
                                    <p>{(new Date(this.props.data.deviceActiveContent.warranty_start_date).toDateString())}</p>
                                </div>
                                <div className='uk-width-1-4@m'>
                                    <h5 className='uk-text-bold'>End Date</h5>
                                </div>
                                <div className='uk-width-3-4@m'>
                                    <p>{(new Date(this.props.data.deviceActiveContent.warranty_end_date).toDateString())}</p>
                                </div>

                            </div>
                        }
                        {this.props.data.deviceNotFound &&
                            <div data-uk-grid className='uk-grid-small'>

                                <div className='uk-width-2-3@m'>
                                    <div className='red_card uk-card-body'>
                                        <p>
                                            The Product Serial Cannot be found in our system.
                                            <br /><br />
                                            Think this is an error, Please contact zinox Support for futher assistance mail support@zinoxtechnologies.com <br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }
                        <hr />

                        <a href='/warranty-check' className="uk-button uk-margin uk-margin-right slider_btn">Check Another Product</a>
                    </div>
                </section>
            </div>
        )
    }
}

class Warranty extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: [],
            product_id: '',
            serial: '',
            isProcessing: false,
            showResult: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        this.getProduct()
    }
    handleInputChange(event) { //stores input values in states
        this.setState({ [event.target.name]: event.target.value });
    }
    handleChange = event => {
        this.setState({ product_id: event.value });
    };
    getProduct = () => {
        axios.get('https://cloud.zinoxtechnologies.com/api/products')
            .then((response) => {
                this.setState({
                    product: response.data
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            isProcessing: true,
        });
        var comp = this
        const payload = {
            serial: this.state.serial,
            product_id: this.state.product_id
        }
        axios.post('https://cloud.zinoxtechnologies.com/api/checkwarrantystatus', payload)
            .then((response) => {
                console.log('checkSerial response:', response)
                this.setState({
                    isProcessingSerialCheck: false
                })
                if (response.status === 204 && response.data === '') {
                    this.setState({
                        deviceNotFound: true,
                        showResult: true
                    })
                }
                if (response.status === 200 && response.data[0].status === 1) {
                    this.setState({
                        deviceActive: true,
                        showResult: true,
                        deviceActiveContent: response.data[0]
                    })
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    renderForm() {
        const options = this.state.product.map((product, index) => {
            return {
                label: product.device_model,
                value: product._id,
                key: index
            }
        })
        return (
            <div>
                <section className="uk-section">
                    <div className="uk-container">
                        <h4 className='uk-text-left'><span className='red'>Zinox Customer Support</span> - Product Warranty Check</h4>
                        <hr />
                        <p>Check a single product warranty</p>
                        <div className='uk-width-1-2@m'>
                            <div className="uk-background-muted uk-padding">
                                <div className="uk-margin">
                                    <h4 className='uk-text-bold'>Warranty Check</h4>
                                    <form method='POST' onSubmit={this.handleSubmit} >
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-bold ">Device Model <span className='red'>*</span></label>
                                            <Select className="calc_input" options={options} name='product_id' onChange={this.handleChange} required />
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-bold ">Serial Number <span className='red'>* </span>
                                                <a href="#modal-sections" data-uk-tooltip="How to find serial" data-uk-toggle="target: #serial"><img src={Question} alt='icon' width='13' /></a></label>
                                            <div className="calc_input uk-margin-small-top">
                                                <input className="uk-input " id="form-stacked-text" type="text" name='serial' placeholder="Serial Number" onChange={this.handleInputChange} required />
                                            </div>
                                            <p className='uk-text-meta black uk-margin-small serial_example'>Example: ZAIOi3Q-0001</p>
                                        </div>
                                        <div id="serial" data-uk-modal>
                                            <div className="uk-modal-dialog uk-margin-auto-vertical uk-width-3-5@m">
                                                <button className="uk-modal-close-default" type="button" data-uk-close></button>
                                                <div className="uk-modal-header">
                                                    <h4 className="uk-text-bold"><img src={logo} alt='logo' width='100' />How do I find my Device Model or serial number?</h4>
                                                </div>
                                                <div className="uk-modal-body">
                                                    <div data-uk-grid>
                                                        <div className='uk-width-1-3@m'>
                                                            <img src={serial} alt='serial' />
                                                        </div>
                                                        <div className='uk-width-2-3@m'>
                                                            <h4 className="uk-text-bold red">Instructions</h4>
                                                            <p>
                                                                How to find your serial number:
                                                                Zinox laptops, tablets and Desktop serial numbers may be located in one of these locations.
                                                            </p>
                                                            <ol>
                                                                <li>· On the bottom or back edge of the laptop.</li>
                                                                <li>· If the battery is removable, inside the battery compartment.</li>
                                                                <li>· If the laptop separates into a tablet, on the edge where the tablet connects to the keyboard.</li>
                                                                <li>· For Desktops, the serial number is found on the side of the desktop.</li>
                                                                <li>· For "all in one" models, the serial number is either located on the back of the screen or under the base.</li>

                                                            </ol>
                                                            <p>
                                                                Televisions:
                                                                · At the back of the TV set
                                                                Generators:
                                                                · On the engine block
                                                            </p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="uk-modal-footer uk-text-right">
                                                <p className='uk-text-meta'>© 2020 Zinox Technologies Ltd.</p>
                                            </div>
                                        </div>
                                        </div>
                                    <div className="uk-margin-large-top">
                                        {!this.state.isProcessing && <button type='submit' className="uk-button uk-margin-right uk-width-2-3@m slider_btn">Check Warranty</button>}
                                        {this.state.isProcessing && <h4 className="uk-form-label uk-text-bold "><img className='uk-margin-right' src={loader} width='40' alt='loader' /> Checking for Warranty</h4>}
                                    </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>
            </div >
        )
    }
    render() {
        if (this.state.showResult) {
            return (
                <div>
                    <section className="uk-section uk-section-small">
                    </section>
                    
                    <WarrantyResult data={this.state} />
                </div>
            )
        }
        else {
            return (
                <div>
                    <section className="uk-section uk-section-small">
                    </section>
                    
                    {this.renderForm()}
                </div>
            )
        }
    }

}
export default Warranty

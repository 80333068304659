import React, { Component } from 'react';


class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: '', os: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOsChange = this.handleOsChange.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        this.setState({ product: event.target.value, os: '' });
    }
    handleOsChange(event) {
        this.setState({ os: event.target.value });
    }

    render() {
        var total = (this.state.totaltv + this.state.totalfan + this.state.totalbulb + this.state.totalfreezer + this.state.totalac + this.state.totalfridge + this.state.totallaptop) / 1000;
        return (
            <div >

                <section>
                    <nav className="uk-navbar-container uk-margin uk-padding-left uk-padding-large uk-padding-remove-vertical dark_nav_bar" data-uk-navbar>
                        <div className="uk-navbar-left uk-margin-xlarge-left">
                            <ul className="uk-navbar-nav">
                                <li ><a className="subnav subnav_active" href="/calculator"></a></li>

                            </ul>
                        </div>
                    </nav>
                </section>
                <section className="uk-section">
                    <div className="uk-container">
                        <h4 className='uk-text-left'>Drivers, Firmware & Software</h4>
                        <hr />
                        <p>Original files and updates for connectivity, productivity and more. </p>

                        <div className="uk-grid-medium uk-child-width-1-4@s uk-text-left uk-margin-medium-top" data-uk-grid>
                            <div>
                                <div className="uk-margin">
                                    <label className="uk-form-label uk-text-bold">Product Model</label>
                                    <div className="">
                                        <select className="uk-select calc_input" onChange={this.handleInputChange}>
                                            <option value='' defaultValue >Select a Product</option>
                                            <option value='all-in-one'>ALL IN ONE PC</option>
                                            <option value='legacy'>LEGACY</option>
                                            <option value='bijimi-laptop'>BIJIMI LAPTOP</option>
                                            <option value='flagship-w251eu'>FLAGSHIP CORE I7 (W251EU) </option>
                                            <option value='classmate'>CLASSMATE PC</option>
                                            <option value='flagship-w251huq'>FLAGSHIP CORE I7 (W251HUQ)</option>
                                            <option value='smart-plus'>SMART PLUS</option>
                                            <option value='flagship-w25cev'>FLAGSHIP_CORE I7 (W25CEV)</option>
                                            <option value='flagship-w25csv'>FLAGSHIP_CORE I7 (W25CSV)</option>
                                            <option value='prestige-w550eu'>PRESTIGE (W550EU)</option>
                                            <option value='desktop'>DESKTOP</option>
                                            <option value='whizkid-w210cuq'>WHIZKID (W210CUQ)</option>
                                            <option value='whizkid-w515tu'>WHIZKID(W515TU)</option>
                                            <option value='flagship-n350dv'>FLAGSHIP CORE I7 (N350DV)</option>
                                            <option value='convertible'>CONVERTIBLE</option>
                                            <option value='prestige-w555suy'>PRESTIGE (W555SUY) </option>


                                        </select>
                                    </div>
                                </div>
                            </div>
                            {this.state.product === 'all-in-one' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-8.1'>WINDOWS 8.1</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'legacy' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-8.1'>WINDOWS 8.1</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'bijimi-laptop' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'flagship-w251eu' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'classmate' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-8.1'>WINDOWS 8.1</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'flagship-w251huq' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'smart-plus' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                                <option value='windows-XP'>WINDOWS XP</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'flagship-w25cev' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-8'>WINDOWS 8</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'flagship-w25csv' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-8.1'>WINDOWS 8.1</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'prestige-w550eu' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-8'>WINDOWS 8</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'desktop' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                                <option value='windows-XP'>WINDOWS XP</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'whizkid-w210cuq' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'whizkid-w515tu' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-8.1'>WINDOWS 8.1</option>
                                                <option value='windows-8'>WINDOWS 8</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'flagship-n350dv' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-10'>WINDOWS 10</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'convertible' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.product === 'prestige-w555suy' &&
                                <div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label uk-text-bold">Operating System</label>
                                        <div className="">
                                            <select className="uk-select calc_input" onChange={this.handleOsChange}>
                                                <option value='' defaultValue >Select an Operating System</option>
                                                <option value='windows-8.1'>WINDOWS 8.1</option>
                                                <option value='windows-7'>WINDOWS 7</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="uk-grid-small " data-uk-grid>
                            <div className='uk-width-1-1 uk-margin'>
                                {this.state.os &&
                                    <section>
                                        <nav className="uk-navbar-container uk-padding-left red_nav_bar uk-margin-top" data-uk-navbar>
                                            <div className="uk-navbar-left ">
                                                <ul className="uk-navbar-nav">
                                                    <li ><a className="subnav subnav_active" style={{ minHeight: '50px' }} href="/"><h5 className='white uk-margin-remove'>Driver Files</h5></a></li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </section>
                                }
                            </div>
                        </div>
                        {(this.state.product === 'all-in-one' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Bluetooth </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: bt.zip  |  Size: 318 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Audio </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: audio.zip  |  Size: 124 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Camera </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: camera.zip  |  Size: 46.7 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/Camera.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Card Reader </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: Cardreader.zip  |  Size: 10.6 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/Cardreader.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Chipset </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: Chipset.zip  |  Size:  2.6 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/Chipset.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Hot Key </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: Hotkey.zip  |  Size:  17.2MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/Hotkey.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Intel </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: Intel.zip  |  Size: 108 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/Intel.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Intel Wireless Music Driver </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: IntelWirelessMusicDriver201.5.5323.0.zip  |  Size: 5 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/IntelWirelessMusicDriver%201.5.5323.0.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MEI </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: MEI.zip  |  Size:  32MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/MEI.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>RTL8723 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: RTL8723.zip  |  Size:  61 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/RTL8723.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TPM </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: TPM.zip  |  Size:  60 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/TPM.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>USB </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: USB.zip  |  Size:  6 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/USB.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA (x64) </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: VGA x64.zip  |  Size:  168MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/VGA%20x64.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA (x86)  </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: VGA x86.zip  |  Size: 116 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/VGA%20x86.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Wireless LAN </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: wlan RTL8188.zip  |  Size: 29 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/WLAN%20RTL8188.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Wireless LAN </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: WLAN.zip  |  Size: 604 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WIDI </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: WiDi.zip  |  Size: 224 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/WiDi.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Wireless Music Adapter  Firmware </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: WirelessMusic_Adapter_Firmware_v39.zip  |  Size: 9 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%207/WirelessMusic_Adapter_Firmware_v39.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }

                        {(this.state.product === 'all-in-one' && this.state.os === 'windows-8.1') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Bluetooth </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: bt.zip  |  Size: 65 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/7260%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Wireless LAN </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: WLAN.zip  |  Size: 245 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/7260%20WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>System Brightness </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: SystemBrightness.zip  |  Size: 11.2 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/8.1%20SystemBrightness.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Airplane Mode </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: AirplaneMode.zip  |  Size: 92 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/AirplaneMode.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Audio </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: audio.zip  |  Size:  225 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Card Reader </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: Cardread.zip  |  Size:  106 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/Cardread.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Chipset </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: Chipset.zip  |  Size: 55 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/Chipset.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Intel Dashboard</h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: IntelDashboard.zip  |  Size: 250 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/INTEL%20Dashboard.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Intel WLAN </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: INTELWLAN.zip  |  Size:  245 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/INTEL%20WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Bluetooth     </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: WinBT.zip  |  Size: 65 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/INTEL%20WinBT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>INTEL VGA </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: IntelVGA.zip  |  Size: 143 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/IntelVGA.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: LAN.zip  |  Size: 79 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/LAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Card </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: .zip  |  Size:  MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MEI </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: MEI.zip  |  Size: 63 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/MEI.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Realtek Bluetooth </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: RealtekBT.zip  |  Size: 63 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/Realtek%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TPM </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: TPM.zip  |  Size: 77 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/TPM.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: WLAN.zip  |  Size: 46 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/ALL-IN-ONE_PC/ALL-IN-ONE_PC/WIN%208.1/WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                            </div>
                        }

                        {(this.state.product === 'bijimi-laptop' && this.state.os === 'windows-7') &&

                            <div className="uk-grid-small " data-uk-grid>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>1.3M </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: 1.3M.zip  |  Size: 38.8 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/1.3M.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>3G </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: 3GEM770.zip  |  Size: 12 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/3G%20EM770.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>3G H20 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: 3GH20.zip  |  Size: 10 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/3G%20H20.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Audio </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: Audio.zip  |  Size: 63 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Camera </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: camera.zip  |  Size: 30 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/CAMERA%20300K.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Card Reader </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: CardReader.zip  |  Size: 2 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/CardReader.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Chipset </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: Chipset.zip  |  Size: 3 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/Chipset.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Hotkey </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: Hotkey.zip  |  Size: 17 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/Hotkey.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Card </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: .zip  |  Size:  MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: lan.zip  |  Size: 26 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/LAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Touchpad </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: Touchpad.zip  |  Size: 28 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/Touchpad.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA x32 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: VGAx32.zip  |  Size: 25 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/VGA%20x32.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA x64 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: VGAx64.zip  |  Size: 25 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/VGA%20x32.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN 8188CE </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: wlan208188CE.zip  |  Size: 72 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/WLAN%208188CE%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAn </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: RTL8191SE_Win7.zip  |  Size: 17 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/WLAN%20RTL8191SE_Win7.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAn </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: WLAN.zip  |  Size: 18 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/BIJIMI_LAPTOP/BIJIMI_LAPTOP/WIN%207/WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                            </div>
                        }

                        {(this.state.product === 'classmate' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>7304_PG398_Win8.1_Win8_Win7_WHQL.zip              </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:  234042560 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/7304_PG398_Win8.1_Win8_Win7_WHQL.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>AP HDPInstall1.6.7.97.zip                         </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:   223 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/AP%20HDPInstall1.6.7.97.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>AP LabCamera_v6.6.391.zip                         </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:   75 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/AP%20LabCamera_v6.6.391.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>AP MediaCamera_v2.3.391.zip                       </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:   45 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/AP%20MediaCamera_v2.3.391.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>CARDREADER RtsUVStor_6.2.9200.39052.zip           </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:   10 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/CARDREADER%20RtsUVStor_6.2.9200.39052.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>CHIPSET 9.4.4.1006_infinst_autol.zip              </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:    5 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/CHIPSET%209.4.4.1006_infinst_autol.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN Install_Win7_7080_03212014.zip                </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:    6 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/LAN%20Install_Win7_7080_03212014.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>OSD _v3.2.1.7348.zip                              </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:   11 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/OSD%20_v3.2.1.7348.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>RUNTIME 64_v3.2.1.7360.zip                        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:   23 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/RUNTIME%2064_v3.2.1.7360.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TXE Bay Trail-MD_Intel(R)_TXE_FW_1.1.0.1089 PR ..></h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:   49 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/TXE%20Bay%20Trail-MD_Intel%28R%29_TXE_FW_1.1.0.1089%20PR%20Update%282%29.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>USB3.0 Intel_V3.0.0.19.zip                        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:    5 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/USB3.0%20Intel_V3.0.0.19.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA EF10MIx_x64_15.33.32.64.4061.zip              </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:  130 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/VGA%20EF10MIx_x64_15.33.32.64.4061.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN 16.8.0.6.zip                                 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:  293 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/WLAN%2016.8.0.6.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN WIFI SHWFW0538G.zip                          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:  275 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/WLAN%20WIFI%20SHWFW0538G.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN_BT Bluetooth_3.1.1311.400.zip                </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:  233 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/WLAN_BT Bluetooth_3.1.1311.400.zip ' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN_BT RTBlueR_Windows_816.816.818.0813.2014_1..></h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:   64 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/WLAN_BT%20RTBlueR_Windows_816.816.818.0813.2014_1.00.234_FC600_LB.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN_BT RTLWlanE_WindowsDriver_2013.4.0715.2014..></h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:   42 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/WLAN_BT%20RTLWlanE_WindowsDriver_2013.4.0715.2014_ISS_1.20.0243.L.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN_BT WWBTW1007_17.1.1407.02G.zip               </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:  201 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/WLAN_BT%20WWBTW1007_17.1.1407.02G.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN_BT WWBTW1104_17.1.1411.01G.zip               </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:  201 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/WLAN_BT%20WWBTW1104_17.1.1411.01G.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>windows6.1-kb2685811-x64.zip                      </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File:   | Size:     800 KB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%207/windows6.1-kb2685811-x64.zip ' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'classmate' && this.state.os === 'windows-8.1') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>EF10MI2 DRIVER   </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 1.7 GB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CLASSMATE_PC/CLASSMATE_PC/WIN%208.1/EF10MI2%20DRIVER.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'convertible' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>M111X </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 830 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/CONVERTIBLE/CONVERTIBLE/WIN%207/M111X_1.00.18.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'desktop' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Audio </h4>
                                    <p className='uk-margin-remove uk-text-meta'> File: AUDIO Realtek_6449.zip | 123 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/DESKTOP/DESKTOP/WIN%207/AUDIO%20Realtek_6449.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Chipset      </h4>
                                    <p className='uk-margin-remove uk-text-meta'> File: Chipset_INF.zip      |   2 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/DESKTOP/DESKTOP/WIN%207/Chipset_INF.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>IMEI              </h4>
                                    <p className='uk-margin-remove uk-text-meta'> File: IMEI.zip              |  10 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/DESKTOP/DESKTOP/WIN%207/IMEI.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>IRST             </h4>
                                    <p className='uk-margin-remove uk-text-meta'> File: IRST.zip             |   1 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/DESKTOP/DESKTOP/WIN%207/IRST.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN 32bit         </h4>
                                    <p className='uk-margin-remove uk-text-meta'> File: LAN 32bit.zip         |  28 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/DESKTOP/DESKTOP/WIN%207/LAN%2032bit.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN 64bit         </h4>
                                    <p className='uk-margin-remove uk-text-meta'> File: LAN 64bit.zip         |  30 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/DESKTOP/DESKTOP/WIN%207/LAN%2064bit.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TPM           </h4>
                                    <p className='uk-margin-remove uk-text-meta'> File: TPM.zip              |   1 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/DESKTOP/DESKTOP/WIN%207/TPM.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>USB           </h4>
                                    <p className='uk-margin-remove uk-text-meta'> File: USB3.zip             |   8 MB </p>
                                </div>

                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/DESKTOP/DESKTOP/WIN%207/USB3.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VIDEO 32bit     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> File: VIDEO 32bit.zip       |  69 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/DESKTOP/DESKTOP/WIN%207/VIDEO%2032bit.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VIDEO 64bit       </h4>
                                    <p className='uk-margin-remove uk-text-meta'> File: VIDEO 64bit.zip       |  86 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/DESKTOP/DESKTOP/WIN%207/VIDEO%2064bit.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'desktop' && this.state.os === 'windows-XP') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VIDEO 32 Bit </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 23 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/DESKTOP/DESKTOP/XP/VIDEO%2032bit.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VIDEO 64 Bit </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 30 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/DESKTOP/DESKTOP/XP/VIDEO%2064bit.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'flagship-n350dv' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>AirplaneMode         </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  39 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/AirplaneMode.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Audio       </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  290 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Cardreader     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 9 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/Cardreader.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Chipset        </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 2 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/Chipset.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>ControlCenter  </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  229 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/ControlCenter.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Finger      </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 49 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/Finger.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>INTEL 7265 BT</h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 24 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/INTEL%207265%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>INTEL 7265 WLAN</h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  363 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/INTEL%207265%20WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>INTEL8260   </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 24 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/INTEL8260%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>INTEL8260 WLAN </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  405 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/INTEL8260%20WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>IRST           </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 13 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/IRST.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN            </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  133 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/LAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MEI_N350DV     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 98 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/MEI_N350DV.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MEI_N350DW     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 22 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/MEI_N350DW.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>SGX          </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 4 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/SGX.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Touchpad       </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 61 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/Touchpad.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA win64      </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  182 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/VGA%20win64.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>vPro_Widi     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  111 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/vPro_Widi.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'flagship-n350dv' && this.state.os === 'windows-10') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>AirplaneMode         </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  39 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/AirplaneMode.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Audio       </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  290 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Cardreader     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 9 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/Cardreader.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Chipset        </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 2 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/Chipset.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>ControlCenter  </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  229 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/ControlCenter.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Finger      </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 49 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/Finger.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>INTEL 7265 BT</h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 24 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/INTEL%207265%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>INTEL 7265 WLAN</h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  363 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/INTEL%207265%20WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>INTEL8260   </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 24 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/INTEL8260%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>INTEL8260 WLAN </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  405 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/INTEL8260%20WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>IRST           </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 13 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/IRST.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN            </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  133 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/LAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MEI_N350DV     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 98 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/MEI_N350DV.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MEI_N350DW     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 22 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/MEI_N350DW.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>SGX          </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 4 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/SGX.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Touchpad       </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 61 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/Touchpad.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA win64      </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  182 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/VGA%20win64.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>vPro_Widi     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  111 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BN350DV%7D/FLAGSHIP_COREI-7%7BN350DV%7D/WIN%207/vPro_Widi.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'flagship-w251eu' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>W2XXEU_EL_1.00.10   </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: W2XXEU_EL_1.00.10.zip | Size: 2.6 GB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW251EU%7D/FLAGSHIP_COREI-7%7BW251EU%7D/WIN%207/W2XXEU_EL_1.00.10.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'flagship-w251huq' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>W24xHUQ_1.00.10   </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: W24xHUQ_1.00.07.zip | Size: 910 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW251HUQ%7D/FLAGSHIP_COREI-7%7BW251HUQ%7D/WIN%207/W24xHUQ_1.00.07.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }

                        {(this.state.product === 'flagship-w25cev' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>3G EM820W</h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 24 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/3G%20EM820W.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>3G F3307</h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 58 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/3G%20F3307.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>3G MC8305</h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 96 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/3G%20MC8305.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>8188CE_BT BT </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 50 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/8188CE_BT%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>8188CE_BT WLAN</h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 29 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/8188CE_BT%20WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>8723AE BT   </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 28 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/8723AE%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>8723AE WLAN   </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 35 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/8723AE%20WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Audio      </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:131 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Camera      </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 97 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/Camera.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Cardreader     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 11 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/Cardreader.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Chipset      </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  2 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/Chipset.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>FingerPrint   </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:194 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/FingerPrint.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Hotkey        </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 25 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/Hotkey.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>INTEL_COMBO BT </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:151 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/INTEL_COMBO%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>INTEL_COMBO WLAN</h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:112 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/INTEL_COMBO%20WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>IRST          </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 11 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/IRST.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>ISCT          </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  9 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/ISCT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Intel_WiDi      </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:139 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/Intel_WiDi.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>JACKSONPEAK BT  </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:336 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/JACKSONPEAK%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>JACKSONPEAK WLAN</h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:404 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/JACKSONPEAK%20WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN             </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:112 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/LAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MEI             </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 43 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/MEI.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>SBA             </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 61 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/SBA.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TPM            </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 61 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/TPM.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Touchpad        </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 42 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/Touchpad.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>USB30           </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  5 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/USB30.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA x64        </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:168 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/VGA%20x64.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA x86        </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:116 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/VGA%20x86.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN 8191SE    </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 27 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/WLAN%208191SE.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN Intel_WMusic</h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  5 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%207/WLAN%20Intel_WMusic.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }

                        {(this.state.product === 'flagship-w25cev' && this.state.os === 'windows-8') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        Bluetooth    </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  28 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/8723AE%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        WLAN  </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  33 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/8723AE%20WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        Audio      </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 130 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        Cardreader </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  11 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/Cardreader.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        Chipset    </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:   2 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/Chipset.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        FingerPrint  </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 194 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/FingerPrint.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        FingerPrint Beta </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 189 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/FingerPrint_Beta.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        Hotkey     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  32 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/Hotkey.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        INTEL BT    </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  93 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/INTEL%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        INTEL Dashboard</h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 250 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/INTEL%20Dashboard.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        INTEL WLAN   </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 172 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/INTEL%20WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        IRST     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  11 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/IRST.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        IRST_Beta   </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  11 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/IRST_Beta.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        ISCT     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  20 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/ISCT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        Intel_WiDi   </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 260 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/Intel_WiDi.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        LAN        </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 163 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/LAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        MEI        </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  44 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/MEI.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        TPM     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  77 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/TPM.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        Touchpad     </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size:  50 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/Touchpad.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        VGA x64      </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 141 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/VGA%20x64.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        VGA x86      </h4>
                                    <p className='uk-margin-remove uk-text-meta'> Size: 112 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CEV%7D/FLAGSHIP_COREI-7%7BW25CEV%7D/WIN%208/VGA%20x86.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }

                        {(this.state.product === 'flagship-w25csv' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>3G EM820W     </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  24 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/3G%20EM820W.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>3G MC7305     </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 207 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/3G%20MC7305.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Audio         </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 228 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT INTEL      </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  68 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/BT%20INTEL.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT Motorola   </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  50 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/BT%20Motorola.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT Realtek    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  63 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/BT%20Realtek.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Card reader    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  10 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/Cardreader.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Chipset       </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   5 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/Chipset.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Fingerprint   </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  38 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/Fingerprint.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Hotkey        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  93 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/Hotkey.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>IRST          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  16 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/IRST.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>IRStart       </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   2 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/IRStart.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>ISCT          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  31 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/ISCT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Intel WiDi    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 215 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/Intel_WiDi.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN           </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  79 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/LAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MEI           </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  55 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/MEI.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>SBA           </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  89 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/SBA.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TPM           </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  77 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/TPM.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Touchpad      </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  57 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/Touchpad.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>USB         </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   5 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/USB30.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA x64       </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 138 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/VGA%20x64.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA x86       </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  75 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/VGA%20x86.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN INTEL    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 249 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/WLAN%20INTEL.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN Realtek  </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  42 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/WLAN%20Realtek.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Webcam        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  48 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%207/Webcam.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'flagship-w25csv' && this.state.os === 'windows-8.1') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>AirplaneMode </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   9 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/AirplaneMode.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Audio        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 228 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT BT_Audio  </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   2 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/BT%20BT_Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT INTEL     </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  65 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/BT%20INTEL.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT Realtek   </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  63 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/BT%20Realtek.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Cardreader   </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  10 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/Cardreader.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Chipset      </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   5 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/Chipset.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Fingerprint  </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  14 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/Fingerprint.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Hotkey       </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  93 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/Hotkey.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>IRST         </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  16 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/IRST.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>IRStart      </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   2 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/IRStart.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>ISCT         </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  31 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/ISCT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  79 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/LAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MEI          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  55 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/MEI.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>SBA          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  89 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/SBA.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TPM          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  77 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/TPM.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Touchpad     </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  57 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/Touchpad.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA x64      </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 138 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/VGA%20x64.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA x86      </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  75 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/VGA%20x86.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN INTEL   </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 249 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/WLAN%20INTEL.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN Realtek </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  42 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/FLAGSHIP_COREI-7%7BW25CSV%7D/FLAGSHIP_COREI-7%7BW25CSV%7D/WIN%208.1/WLAN%20Realtek.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'legacy' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>W540SU1_1.00 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: W540SU1_1.00.zip | Size: 1.6 GB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/LEGACY/LEGACY/WIN%207/W540SU1_1.00.14W7_0324.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'legacy' && this.state.os === 'windows-8.1') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>W540SU1 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: W540SU1.zip | Size: 631 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/LEGACY/LEGACY/WIN%208.1/W540SU1_1.03.11_0328.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'prestige-w550eu' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>AUDIO VIA             </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 130MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/AUDIO%20VIA.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT Intel              </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 79MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/BT%20Intel.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT Motorola           </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/BT%20Motorola.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT RTL8723AE          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 28MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/BT%20RTL8723AE.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>CREADER Realtek       </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 11MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/CREADER%20Realtek.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Chipset               </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 2MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/Chipset.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Hotkey                </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 33MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/Hotkey.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>IRST iata_cd          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 11MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/IRST%20iata_cd.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>IR_Start              </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 2MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/IR_Start.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>ISCT                  </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 20MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/ISCT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN Realtek           </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 5MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/LAN%20Realtek.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MEI                   </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 44MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/MEI.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TPM                   </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 77MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/TPM.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TouchPad              </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 43MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/TouchPad.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA INTEL x64         </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 140MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/VGA%20INTEL%20x64.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA INTEL x86         </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 111MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/VGA%20INTEL%20x86.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN Intel            </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 172MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/WLAN%20Intel.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN Intel_Dashboard  </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 250MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/WLAN%20Intel_Dashboard.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN Intel_WiDi       </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 261MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/WLAN%20Intel_WiDi.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN RTL RTL8723AE    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 37MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%207/WLAN%20RTL%20RTL8723AE.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'prestige-w550eu' && this.state.os === 'windows-8') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>AUDIO VIA             </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 130MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/AUDIO%20VIA.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT Intel              </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 79MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/BT%20Intel.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT Motorola           </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/BT%20Motorola.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT RTL8723AE          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 28MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/BT%20RTL8723AE.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>CREADER Realtek       </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 11MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/CREADER%20Realtek.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Chipset               </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 2MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/Chipset.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Hotkey                </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 33MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/Hotkey.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>IRST iata_cd          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 11MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/IRST%20iata_cd.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>IR_Start              </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 2MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/IR_Start.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>ISCT                  </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 20MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/ISCT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN Realtek           </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 5MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/LAN%20Realtek.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MEI                   </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 44MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/MEI.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TPM                   </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 77MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/TPM.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TouchPad              </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 43MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/TouchPad.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA INTEL x64         </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 140MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/VGA%20INTEL%20x64.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA INTEL x86         </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 111MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/VGA%20INTEL%20x86.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN Intel            </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 172MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/WLAN%20Intel.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN Intel_Dashboard  </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 250MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/WLAN%20Intel_Dashboard.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN Intel_WiDi       </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 261MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/WLAN%20Intel_WiDi.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN RTL RTL8723AE    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 37MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW550EU%7D/PRESTIGE%7BW550EU%7D/WIN%208/WLAN%20RTL%20RTL8723AE.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'convertible' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                            </div>
                        }
                        {(this.state.product === 'prestige-w555suy' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>W5X_W9X_SU2_1.00.05W7_5_2 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: W5X_W9X_SU2_1.00.05W7_5_2.zip | Size: 1.6 GB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW555SUY%7D/PRESTIGE%7BW555SUY%7D/WIN%207/W5X_W9X_SU2_1.00.05W7_5_2.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'prestige-w555suy' && this.state.os === 'windows-8.1') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>W5X_W9X_SU2_1.03.08 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>File: W5X_W9X_SU2_1.03.08.zip| Size: 1.4 GB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/PRESTIGE%7BW555SUY%7D/PRESTIGE%7BW555SUY%7D/WIN%208.1/W5X_W9X_SU2_1.03.08.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'smart-plus' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>3G Sierra       </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 231MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%207/3G%20Sierra.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Audio           </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 597MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%207/Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>CAMERA Bison    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 101MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%207/CAMERA%20Bison.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Hotkey          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  28MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%207/Hotkey.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MODEM AGERE x64 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   7MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%207/MODEM%20AGERE%20x64.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MODEM AGERE x86 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   7MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%207/MODEM%20AGERE%20x86.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MODEM MOTO      </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  29MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%207/MODEM%20MOTO.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Card Reader          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  33MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%207/Reader.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Touchpad        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 278MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%207/Touchpad.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Vga             </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 149MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%207/Vga.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN      </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 125MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%207/WLAN%20GU702.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'smart-plus' && this.state.os === 'windows-XP') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>3G                        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 19 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/3G.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Audio                     </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 43 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Camera                    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 16 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/Camera.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>HOTKEY Vista              </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  2 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/HOTKEY%20Vista.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>HOTKEY XP                 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  3 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/HOTKEY%20XP.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Lan                       </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  5 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/Lan.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MODEM AGERE Vista32       </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:    MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/MODEM%20AGERE%20Vista32.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MODEM AGERE Vista64       </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:    MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/MODEM%20AGERE%20Vista64.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MODEM Vista               </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  2 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/MODEM%20Vista.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MODEM XP                  </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  2 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/MODEM%20XP.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Card Reader                    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  1 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/Reader.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TOUCHPAD VISTA WINWDF x64 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  6 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/TOUCHPAD%20VISTA%20WINWDF%20x64.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TOUCHPAD VISTA WINWDF x86 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  5 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/TOUCHPAD%20VISTA%20WINWDF%20x86.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TOUCHPAD XP               </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 24 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/TOUCHPAD%20XP.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>USB                </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:    MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/USB_hotfix.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA Vista                 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 14 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/VGA%20Vista.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA XP                    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 17 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/VGA%20XP.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN GU700                </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 15 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/WLAN%20GU700.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN GU702                </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 15 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/SMART_PLUS/SMART_PLUS/WIN%20XP/WLAN%20GU702.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        } {(this.state.product === 'whizkid-w210cuq' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Audio      </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  112 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW210CUQ%7D/WHIZKID%7BW210CUQ%7D/WIN%207/Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Cardreader </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   12 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW210CUQ%7D/WHIZKID%7BW210CUQ%7D/WIN%207/Cardreader.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Hotkey     </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   19 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW210CUQ%7D/WHIZKID%7BW210CUQ%7D/WIN%207/Hotkey.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:    7 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW210CUQ%7D/WHIZKID%7BW210CUQ%7D/WIN%207/LAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Touchpad   </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   53 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW210CUQ%7D/WHIZKID%7BW210CUQ%7D/WIN%207/Touchpad.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   17 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW210CUQ%7D/WHIZKID%7BW210CUQ%7D/WIN%207/VGA.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>W210CUQ_1.00.12</h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  1.2 GB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW210CUQ%7D/WHIZKID%7BW210CUQ%7D/WIN%207/W210CUQ_1.00.12.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>chipset    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:    2 MB </p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW210CUQ%7D/WHIZKID%7BW210CUQ%7D/WIN%207/chipset.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'whizkid-w515tu' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>
                                        Windows 7 </h4>
                                    <p className='uk-margin-remove uk-text-meta'>
                                        File: win7.zip | Size: MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%207/WIN%207.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                            </div>
                        }
                        {(this.state.product === 'whizkid-w515tu' && this.state.os === 'windows-8') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Airplane  Mode      </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   9 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/Airplane.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Audio           </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 123 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT MTK          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  85 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/BT%20MTK.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT RTL8723AE    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  63 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/BT%20RTL8723AE.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Cardreader      </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  12 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/Cardreader.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Chipset         </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   2 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/Chipset.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Hotkey          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  73 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/Hotkey.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN             </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   6 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/LAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MBI             </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   1 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/MBI.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TPM.zip             </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  77 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/TPM.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TXE             </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  36 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/TXE.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Touchpad        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  57 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/Touchpad.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA x64         </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 149 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/VGA%20x64.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN 7260 BT    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 199 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/WLAN%207260%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN 7260 WLAN  </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 360 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/WLAN%207260%20WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN MTK        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  22 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/WLAN%20MTK.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN RTL        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  50 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href='http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208/WLAN%20RTL.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                            </div>
                        }
                        {(this.state.product === 'whizkid-w515tu' && this.state.os === 'windows-8.1') &&
                            <div className="uk-grid-small " data-uk-grid>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Airplane  Mode      </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   9 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/Airplane.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Audio           </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 123 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/Audio.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT MTK          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  85 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/BT%20MTK.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>BT RTL8723AE    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  63 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/BT%20RTL8723AE.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Cardreader      </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  12 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/Cardreader.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Chipset         </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   2 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/Chipset.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Hotkey          </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  73 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/Hotkey.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>LAN             </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   6 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/LAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>MBI             </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:   1 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/MBI.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TPM.zip             </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  77 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/TPM.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>TXE             </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  36 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/TXE.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>Touchpad        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  57 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/Touchpad.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>VGA x64         </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 149 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/VGA%20x64.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN 7260 BT    </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 199 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/WLAN%207260%20BT.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN 7260 WLAN  </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size: 360 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/WLAN%207260%20WLAN.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN MTK        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  22 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/WLAN%20MTK.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>
                                <div className='uk-width-4-5@m uk-padding uk-padding-remove-vertical uk-text-left'>
                                    <h4 className='red uk-margin-small-bottom'>WLAN RTL        </h4>
                                    <p className='uk-margin-remove uk-text-meta'>Size:  50 MB</p>
                                </div>
                                <div className='uk-width-1-5@m uk-padding uk-padding-remove-vertical uk-text-left '>
                                    <a href=' http://165.90.240.94/zinox_drivers/WHIZKID%7BW515TU%7D/WHIZKID%7BW515TU%7D/WIN%208.1/WLAN%20RTL.zip' className="uk-button uk-button-small  slider_btn">Download</a>
                                </div>
                                <div className='uk-width-1-1 uk-margin'>
                                    <hr />
                                </div>

                            </div>
                        }
                        {(this.state.product === 'convertible' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                            </div>
                        } {(this.state.product === 'convertible' && this.state.os === 'windows-7') &&
                            <div className="uk-grid-small " data-uk-grid>
                            </div>
                        }

                    </div>
                </section>

            </div>

        )
    }
}

export default Support